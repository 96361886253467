.input-icon-end {
    position: relative;
    input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid gainsboro;
        padding-right: 45px;
    }
    span {
        position: absolute;
        right: 9px;
        top: 7px;
    }
}

.input-icon-start {
    position: relative;
    input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid gainsboro;
        padding-left: 45px;
    }
    span {
        position: absolute;
        left: 9px;
        top: 7px;
    }
}
