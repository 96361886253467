@import "code.scss";
.btn-primary {
    background-color: $primary !important;
    border: none !important;
    color: white;
    &:hover {
        opacity: 0.8;
    }
}

.btn-sec,
.btn-secondary,
.btn-cancel {
    background-color: $quinary !important;
    border: none !important;
    color: $secondary !important;
    &:hover {
        opacity: 0.8;
    }
}

.btn {
    &.icon-start {
        position: relative;
        padding-left: 40px;
        span {
            position: absolute;
        }
        &.lg > span {
            top: 12px;
            left: 12px;
        }
        &.sm > span {
            top: 9px;
            left: 12px;
        }
    }

    &.icon-end {
        position: relative;
        padding-right: 40px;
        span {
            position: absolute;
        }
        &.lg > span {
            top: 12px;
            right: 12px;
        }

        &.sm > span {
            top: 9px;
            right: 12px;
        }
    }

    &.icon-auto {
        display: flex;
        padding: 12px;
    }

    &.rounded {
        border-radius: 30px !important;
    }
    &.lg {
        height: 50px;
    }
    &.sm {
        height: 43px;
    }
}
