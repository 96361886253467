@import "code.scss";
table {
    thead {
        tr > th {
            background-color: $quinary !important;
            color: $secondary !important;
        }
    }
    tbody {
        tr > td {
            background-color: $tertiary !important;
        }
    }
}
