@import "code.scss";
.shadow,
.box-item {
    color: black;
    background-color: $quaternary;
    border: 1px solid $quaternary;
    &.rd {
        border-radius: 20px;
    }
    span,
    p {
        font-weight: 500;
    }
    &.def {
        background-color: white;
        border: 1px solid white;
    }
    &.secondary {
        color: $secondary;
        background-color: white;
        border: 1px solid white;
    }

    &.primary {
        color: white;
        background-color: $primary;
        border: 1px solid $primary;
    }

    &.action {
        color: #0d266d;
        background-color: $quinary;
        border: 1px solid $quinary;
    }
}
