
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.define-palette(mat.$indigo-palette);
$frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat.define-light-theme((
  color: (
    primary: $frontend-primary,
    accent: $frontend-accent,
    warn: $frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-theme);

// @tailwind base;
// @tailwind components;
// @tailwind utilities;
@import "primeicons/primeicons.css";
@import "input.scss";
@import "button.scss";
@import "boxitem.scss";
@import "code.scss";
@import "table.scss";
@import "alert.scss";
@import "text.scss";
body {
    // font-family: "Kanit", sans-serif;
    font-family: "Montserrat", sans-serif !important;
    background-color: $tertiary !important;
}

// .montserrat-<uniquifier> {
//     font-family: "Montserrat", sans-serif;
//     font-optical-sizing: auto;
//     font-weight: <weight>;
//     font-style: normal;
//   }

.move-action,
.m-action {
    cursor: pointer;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
